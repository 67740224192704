import * as React from "react";
import { SimpleContainerFlexModuleResult, SimpleContainerProps } from "./typings";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { getExtraClassNameBasedOnParameters } from "./utilityStyles";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { UitkHeading } from "@egds/react-core/text";
import { RegionChildrenWithGridContainer } from "src/utils/RegionUtils";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";

export interface SimpleContainerContext {
  modulesHaveBorder?: boolean;
}

const defaultContext = {
  modulesHaveBorder: false,
};

export const SimpleContainerContext = React.createContext<SimpleContainerContext>(defaultContext);

export const SimpleContainer = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: SimpleContainerProps) => {
    const { blossomComponent, templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore next */
    if (!templateComponent || !blossomComponent) {
      return null;
    }

    const {
      metadata: { name },
      config: { view, rfrrID },
    } = templateComponent;

    const impressionLinkName = rfrrID + " Link";
    const impressionRfrrID = `${rfrrID}.Impression`;

    let regionView = name || "";
    if (view) {
      regionView = view;
    }

    const model = flexModuleModelStore.getModel(
      templateComponent.metadata.id
    ) as SimpleContainerFlexModuleResult | null;

    if (!model || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const {
      mobileWidth,
      title,
      innerTitle,
      a11yRegionLabel,
      a11yRegionAriaLabelTitle,
      tabletWidth,
      desktopWidth,
      hasBorder: modulesHaveBorder,
      titleSize,
    } = model;

    const regionSpecificClasses = getRegionSpecificClasses(regionView);
    const regionSpecificLabel =
      title || innerTitle || a11yRegionAriaLabelTitle || a11yRegionLabel || getRegionSpecificLabels(regionView);

    const flexContent = (
      <>
        {title && (
          <UitkHeading tag="h2" size={titleSize || 3}>
            {title}
          </UitkHeading>
        )}
        {innerTitle && (
          <UitkHeading tag="h2" size={titleSize || 3}>
            {innerTitle}
          </UitkHeading>
        )}
        <RegionChildrenWithGridContainer templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </>
    );

    const commonClassNames = [regionSpecificClasses, getExtraClassNameBasedOnParameters(model, regionView)]
      .filter((classes) => classes !== "")
      .join(" ");

    const regionA11yAttrs: any = {};
    if (regionSpecificLabel) {
      regionA11yAttrs["role"] = "region";
      regionA11yAttrs["aria-label"] = regionSpecificLabel;
    }

    const marginValue = getRegionMarginValue(regionView);
    const paddingValue = getRegionPaddingValue(regionView);

    const simpleContainerView = (
      <Viewport>
        {mobileWidth === "hidden" ? (
          <ViewSmall />
        ) : (
          <ViewSmall>
            <UitkSpacing margin={marginValue} padding={paddingValue}>
              <div
                className={`SimpleContainer ${regionView === "dx-page-header" ? " elevation" : ""} ${commonClassNames}`}
                {...regionA11yAttrs}
              >
                {flexContent}
              </div>
            </UitkSpacing>
          </ViewSmall>
        )}

        {tabletWidth === "hidden" ? (
          <ViewMedium />
        ) : (
          <ViewMedium>
            <UitkSpacing margin={marginValue} padding={paddingValue}>
              <div
                className={`SimpleContainer ${regionView === "dx-page-header" ? " elevation" : ""} ${commonClassNames}`}
                {...regionA11yAttrs}
              >
                {flexContent}
              </div>
            </UitkSpacing>
          </ViewMedium>
        )}

        {desktopWidth === "hidden" ? (
          <ViewLarge />
        ) : (
          <ViewLarge>
            <UitkSpacing margin={marginValue} padding={paddingValue}>
              <div
                className={`${commonClassNames} ${regionView === "dx-page-header" ? "elevation" : " SimpleContainer"}`}
                {...regionA11yAttrs}
              >
                {flexContent}
              </div>
            </UitkSpacing>
          </ViewLarge>
        )}
      </Viewport>
    );
    return (
      <SimpleContainerContext.Provider value={{ modulesHaveBorder }}>
        {rfrrID ? (
          <IntersectionTracker linkName={impressionLinkName} referrerId={impressionRfrrID}>
            {simpleContainerView}
          </IntersectionTracker>
        ) : (
          <>{simpleContainerView}</>
        )}
      </SimpleContainerContext.Provider>
    );
  })
);

/**
 * Return the classes corresponding to a specific region. This is
 * a temporary solution until Module groups are implemented
 * @return {string} classes to decorate region
 * @param regionView
 */
const getRegionSpecificClasses = (regionView?: string): string => {
  switch (regionView) {
    case "wizard":
      return "wizardHero whiteBackground";
    case "FullWidthWizard":
      return "wizardHero fullWidth";
    case "BottomPaddingWizard":
      return "wizardHero fullWidth bottomPadding";
    case "FullWidthBreadcrumbs":
      return "fullWidthBreadcrumbs";
    case "SEO links":
      return "SEOLinks";
    case "CenteredDestinationWizard":
      return "CenteredDestinationWizard";
    default:
      return "";
  }
};

const getRegionSpecificLabels = (regionView?: string): string => {
  switch (regionView) {
    case "banner_ad_1":
      return "Banner";
    case "geo_hotels_1":
      return "Explore cabin retreats";
    case "wizard":
    case "FullWidthWizard":
    case "BottomPaddingWizard":
    case "CenteredDestinationWizard":
      return "Wizard";
    case "FullWidthBreadcrumbs":
      return "Breadcrumbs";
    default:
      return "";
  }
};

const getRegionMarginValue = (regionView?: string): UitkSpacingProps["margin"] => {
  if (regionView === "wizard") {
    return { blockend: "three" };
  }

  return undefined;
};

const getRegionPaddingValue = (regionView?: string): UitkSpacingProps["padding"] => {
  if (regionView === "dx-main-content") {
    return { small: { block: "six" }, large: { inline: "three" } };
  }

  return undefined;
};

export default SimpleContainer;
